@import "variables.scss";

.font-size-xs{ font-size: $font-size-xs;}
.font-size-sm{ font-size: $font-size-sm;}
.font-size-md{ font-size: $font-size-md;}
.font-size-lg{ font-size: $font-size-lg;}
.font-size-xl{ font-size: $font-size-xl;}

.w-5 { width: 5% !important;}
.w-10 { width: 10% !important;}
.w-15 { width: 15% !important;}
.w-20 { width: 20% !important;}
.w-25 { width: 25% !important;}
.w-30 { width: 30% !important;}
.w-35 { width: 35% !important;}
.w-40 { width: 40% !important;}
.w-45 { width: 45% !important;}
.w-50 { width: 50% !important;}
.w-55 { width: 55% !important;}
.w-60 { width: 60% !important;}
.w-65 { width: 65% !important;}
.w-70 { width: 70% !important;}
.w-75 { width: 75% !important;}
.w-80 { width: 80% !important;}
.w-85 { width: 85% !important;}
.w-90 { width: 90% !important;}
.w-95 { width: 95% !important;}