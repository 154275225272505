$font-size-xs: 0.5rem;
$font-size-sm: 0.7rem;
$font-size-md: 0.9rem;
$font-size-lg: 1.2rem;
$font-size-xl: 2rem;

$spacing-1: 4px;
$spacing-2: 8px;
$spacing-3: 16px;
$spacing-4: 24px;
$spacing-5: 32px;

$border-radius: 3px;
