@font-face {
    font-family: 'Open Sans';
    src: url(../fonts/Open_Sans/OpenSans-Light.ttf);
    font-weight: 300;
}
@font-face {
    font-family: 'Open Sans';
    src: url(../fonts/Open_Sans/OpenSans-Regular.ttf);
    font-weight: 400;
}
@font-face {
    font-family: 'Open Sans';
    src: url(../fonts/Open_Sans/OpenSans-SemiBold.ttf);
    font-weight: 600;
}
@font-face {
    font-family: 'Open Sans';
    src: url(../fonts/Open_Sans/OpenSans-Bold.ttf);
    font-weight: 700;
}
@font-face {
    font-family: 'Open Sans';
    src: url(../fonts/Open_Sans/OpenSans-ExtraBold.ttf);
    font-weight: 800;
}
