@import "variables.scss";
@import "~hamburgers/dist/hamburgers.min.css";

@-webkit-keyframes blink-2 {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 1;
    }
}

@keyframes blink-2 {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 1;
    }
}

#root {
    background-image: url("/images/bg-admin.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .cms {
        background: rgba(255, 255, 255, 0.9);
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        font-size: $font-size-md;
    }
}

h1 {
    font-size: $font-size-xl;
}

h2 {
    font-size: $font-size-lg;
}

h3 {
    font-size: $font-size-md;
}

h4 {
    font-size: $font-size-sm;
}

h5 {
    font-size: $font-size-xs;
}

input {
    &[type=search] {
        &::after {
            font-family: "Font Awesome 5 Free";
            content: "\f002";
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}

nav {
    background-color: #00519b;

    &.sidebar {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        padding: 70px 0 0;
        width: 300px;
        box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
        z-index: 100;

        &.collapsed {
            left: -300px;
        }

        .navbar-nav {
            &.bottom {
                position: absolute;
                bottom: 0;

                .nav-item {
                    border-top: 1px solid rgb(68, 68, 68);
                    border-bottom: none;
                }
            }

            .nav-item {
                border-bottom: 1px solid rgb(68, 68, 68);

                &.event-menu {
                    max-height: 330px;
                    overflow-y: auto;
                }
                &.dropdown {
                    .dropdown-toggle {
                        &::after {
                            position: absolute;
                            right: $spacing-3;
                            top: $spacing-4;
                        }
                    }
                    .dropdown-menu {
                        padding: 0;
                        background: none;
                        border: none;

                        .dropdown-item {
                            padding-left: $spacing-5;

                            &:hover {
                                background: none;

                                &>.nav-link {
                                    color: #FFF;
                                }
                            }
                        }
                    }
                }
                &:hover {
                    &>.nav-link {
                        color: #FFF;
                    }
                }

                button {
                    &.nav-link {
                        border: none;
                        background: none;
                    }
                }
                .nav-link {
                    color: #FFF;
                }
            }
        }
    }

    .hamburger {
        outline: none;

        &.is-active {
            .hamburger-box {
                .hamburger-inner {
                    &:before, &:after {
                        top: -3px;
                    }
                }
            }
        }

        .hamburger-box {
            width: 20px;
            height: 13px;

            .hamburger-inner {
                &, &:before, &:after {
                    width: 20px;
                    height: 2px;
                    background-color: #FFF;
                }

                &:before {
                    top: 5px;
                }

                &:after {
                    top: 10px;
                }
            }
        }
    }

    .navbar-brand {
        padding-top: 0;
        color: #FFF;
    }

    .navbar-collapse {
        .navbar-nav {
            .nav-item {
                .nav-link {
                    color: #FFF;
                }
            }

            .dropdown {
                .dropdown-menu {
                    top: 120%;

                    .nav-link {
                        color: #1C1C1C;
                    }
                }
            }
        }
    }
}

.badge {
    word-break: break-word;
    white-space: break-spaces;
    text-align: left;
}

.bg-none {
    background: none;
}

.blink-2 {
    -webkit-animation: blink-2 0.9s infinite both;
    animation: blink-2 0.9s infinite both;
}

.btn-group {
    .dropdown-menu {
        left: unset;
        right: 0;
    }
}

.card {
    .card-header {
        background: #176ACF;
        color: #FFF;
        font-size: $font-size-md;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.DateRangePicker {
    z-index: 99;

    .DateRangePickerInput {
        border: none;

        .DateInput {
            .DateInput_input {
                font-size: $font-size-md;
                font-weight: normal;
                padding: 0;
            }
        }
        .DateRangePickerInput_arrow {
            margin: 0 11px 0 0;
        }
    }
}

.dropdown-menu {
    .dropdown-item {
        font-weight: 400;
    }
}

.help {
    margin-right: $spacing-1;
    padding: $spacing-1 $spacing-2;
    border-radius: 2px;
    font-size: $font-size-xs;
}

.img-responsive {
    background-color: rgb(222, 226, 227);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.img-rounded {
    background-size: cover;
    border-radius: 50%;
}

.mm-popup {
    &.mm-popup--visible {
        .mm-popup__box {
            width: 75vw;
            top: 10%;
            left: 35vw;
            max-height: 85vh;
            overflow: auto;

            &.mm-popup__box--zIndex-999999 {
                z-index: 999999;
            }
            &.mm-popup__box--confirmation {
                width: 45vw;
                left: 40vw;
            }
            &.mm-popup__box--full-screen {
                left: 0;
                margin-left: 0;
                width: 100vw;
                height: 90vh;
                max-height: 90vh;
            }
            .mm-popup__close {
                background-image: url("/images/times-circle-regular.svg");
            }
            .mm-popup__box__header {
                background-color: #00519b;
                color: #FFF;
            }
            .mm-popup__box__body {
                overflow-wrap: anywhere;
            }
        }
    }
}

.quill {
    .ql-toolbar {
        background-color: #4a5255;
        .ql-formats {
            color: #FFF;

            .ql-picker {
                .ql-picker-label {
                    &:before {
                        color: #FFF;
                    }
                }
            }
            .ql-stroke {
                stroke: #FFF;
            }
        }
    }
    .ql-container {
        min-height: 100px;
    }
}

.ReactTable {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    .rt-table {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;

        .rt-thead, .rt-tbody {
            .rt-tr {
                .rt-th:first-of-type,
                .rt-td:first-of-type,
                .rt-th:last-of-type,
                .rt-td:last-of-type {
                    width: 16px !important;
                }
            }
        }
        .rt-thead {
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;

            &.-header {
                padding: $spacing-1;
                border-top-left-radius: $border-radius;
                border-top-right-radius: $border-radius;
                background-color: #176ACF;
            }

            &.-filters {
            }

            .rt-tr {
                .rt-th {
                    font-size: $font-size-md;
                    color: #FFF;

                    &.-sort-asc,
                    &.-sort-desc {
                        box-shadow: none;
                    }
                }
            }
        }

        .rt-tbody {
            .rt-tr-group {
                .rt-tr {
                    &.-odd {
                        background-color: #F3F5F6;
                    }

                    &.-even {
                    }

                    .rt-td {
                        align-self: center;
                        font-size: $font-size-sm;

                        .btn-sm {
                            font-size: $font-size-sm;
                            outline: none;
                        }
                        .dropdown-menu {
                            min-width: 7rem;
                            padding: 4px 8px;

                            .dropdown-item {
                                padding: 4px 8px;
                                font-size: $font-size-sm;
                            }
                        }
                    }
                }
            }
        }
    }

    .-pagination {
        .-btn {
            font-size: $font-size-md !important;
        }
    }

    .-pageInfo {
        font-size: $font-size-md !important;
    }

    .-pageSizeOptions {
        height: 33px;
    }

    .-loading {
        .-loading-inner {
            top: 20%;
        }
    }
}

.text-underline {
    text-decoration: underline;
}

.Toastify {
    .Toastify__toast-container {
        .Toastify__toast {
            border-radius: $border-radius;
        }
    }
}

.zIndex0{
    z-index: 0;
}

@media (max-width: 420.98px) {
    .mm-popup {
        &.mm-popup--visible {
            .mm-popup__box {
                top: 12vh;
                left: 12vw;
                &.mm-popup__box--confirmation {
                    width: 100vw;
                    left: 0;
                }
            }
        }
    }
}

@media (min-width: 425px) {
    #root {
        .container {
            max-width: 100vw;
        }
    }

    .mm-popup {
        &.mm-popup--visible {
            .mm-popup__box {
                &.mm-popup__box--confirmation {
                    width: 75vw;
                    left: 30vw;
                }
            }
        }
    }
}

@media (min-width: 421px) and (max-width: 439.98px) {
    .mm-popup {
        &.mm-popup--visible {
            .mm-popup__box {
                left: 52vw;
                top: 10vh;
            }
        }
    }
}

@media (min-width: 440px) and (max-width: 549.98px) {
    .mm-popup {
        &.mm-popup--visible {
            .mm-popup__box {
                left: 52vw;
                top: 10vh;
            }
        }
    }
}

@media (min-width: 550px) and (max-width: 767.98px) {
    .mm-popup {
        &.mm-popup--visible {
            .mm-popup__box {
                left: 42vw;
                top: 10vh;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
    .mm-popup {
        &.mm-popup--visible {
            .mm-popup__box {
                left: 30vw;
                &.mm-popup__box--confirmation {
                    width: 75vw;
                    left: 35vw;
                }
            }
        }
    }
}

@media (min-width: 1024px) and (max-width: 1439.98px) {
    .mm-popup {
        &.mm-popup--visible {
            .mm-popup__box {
                left: 30vw;
            }
        }
    }
}

@media (min-width: 1024px) {
    .mm-popup {
        &.mm-popup--visible {
            .mm-popup__box {
                &.mm-popup__box--confirmation {
                    width: 50vw;
                    left: 40vw;
                }
            }
        }
    }

}

@media (min-width: 1400px) {
    .mm-popup {
        &.mm-popup--visible {
            .mm-popup__box {
                left: 25vw;
            }
        }
    }
}

.cms {
    .event-page-summary {
        .dropdown-menu {
            &.show {
                max-height: 300px;
                overflow-y: auto;
            }
        }
    }
}
